import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text } from '../components';
import ic_whatsapp from '../assets/images/icons/ic_whatsapp.svg';
import ic_instagram from '../assets/images/icons/ic_instagram.svg';
import ic_linkedin from '../assets/images/icons/ic_linkedin.svg';
import corners_plants from '../assets/images/showroom/corners_plants.jpg';
import circles from '../assets/images/logos/circles.svg';
import { useTranslation } from 'react-i18next';

export const ApplyView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeFooterAddressGroup1651536924073OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.google.com\/maps\/place\/Pg.+de+la+Bonanova,+26,+08022+Barcelona\/@41.4050831,2.1281513,17z\/data=!3m1!4b1!4m5!3m4!1s0x12a498138a25248b:0x7ee63331cc4d1f98!8m2!3d41.4050831!4d2.13034","target":"_circlesLocation"}], options)
	}
	const homeFooterPrivacy1651536924073OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/app.mobincube.com\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterCookies1651536924073OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/doyo.tech\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterWhatsappButton1651536924073OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/wa.me\/555555555?text=Hi%20circles!","target":"_circlesInstagram"}], options)
	}
	const homeFooterInstagramButton1651536924073OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/instagram.com","target":"_circlesInstagram"}], options)
	}
	const homeFooterLinkedinButton1651536924073OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/linkedin.com","target":"_circlesLinkedin"}], options)
	}
	const homeHeaderApplyButton1651536924073OnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderSigninButton1651536924073OnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('apply.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="aboutBody1651536924073"
				direction="vertical"
				className="w-100 min-h-100 fg-100 scrollable" >
				<Group
					id="aboutHeader1651536924073"
					direction="auto"
					className="w-100 h-auto px-16px py-48px" >
					<Image
						id="homeHeaderLogo1651536924073"
						src={circles}
						className="w-240px h-auto valign-center me-64px" />
					<Group
						id="homeHeaderButtons1651536924073"
						direction="horizontal"
						className="min-w-220px w-auto h-auto valign-center my-16px" >
						<Text
							id="homeHeaderSigninButton1651536924073"
							onClick={homeHeaderSigninButton1651536924073OnClick}
							className="w-auto h-auto pe-8px py-8px me-24px text-primary ff-regular" >
							{t('apply.homeHeaderSigninButton1651536924073')}
						</Text>
						<Text
							id="homeHeaderApplyButton1651536924073"
							onClick={homeHeaderApplyButton1651536924073OnClick}
							className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px text-text-on-primary ff-regular" >
							{t('apply.homeHeaderApplyButton1651536924073')}
						</Text>
					</Group>
				</Group>
				<Group
					id="aboutContent1651536924073"
					direction="auto"
					className="w-100 h-auto" >
					<Group
						id="aboutColumnLeft1651536924073"
						direction="horizontal"
						className="max-w-24px w-fill min-h-100 h-100" >
						<Group
							id="aboutColumnLeftBar1651536924073"
							direction="vertical"
							className="w-100 min-h-160px h-160px valign-center bg-primary" >
						</Group>
					</Group>
					<Group
						id="aboutColumnCenter1651536924073"
						direction="horizontal"
						className="w-auto min-h-100 h-100" >
						<Image
							id="component1651536924073"
							src={corners_plants}
							className="w-auto min-h-100vh h-100vh" />
					</Group>
					<Group
						id="aboutColumnRight1651536924073"
						direction="vertical"
						className="w-fill min-h-100 h-100" >
						<Group
							id="aboutColumnRightTop1651536924073"
							direction="vertical"
							className="w-100 h-fill" >
							<Group
								id="aboutColumnRightTopWrapper1651536924073"
								direction="vertical"
								className="w-100 h-auto valign-center position-absolute ps-96px pe-20" >
								<Text
									id="aboutColumnRightTopTitle1651536924073"
									tag="h1"
									className="w-100 h-auto valign-center text-primary-text ff-regular" >
									{t('apply.aboutColumnRightTopTitle1651536924073')}
								</Text>
								<Text
									id="aboutColumnRightTopText1651536924073"
									className="w-85 h-auto mt-32px text-primary-text ff-textMedium" >
									{t('apply.aboutColumnRightTopText1651536924073')}
								</Text>
								<Text
									id="aboutColumnRightTopText1651536924073"
									className="w-100 h-auto mt-16px text-primary-text ff-textMedium" >
									{t('apply.aboutColumnRightTopText1651536924073')}
								</Text>
							</Group>
						</Group>
						<Group
							id="aboutColumnRightBar1651536924073"
							direction="horizontal"
							className="w-100 min-h-160px h-160px bg-primary" >
							<Text
								id="aboutMainText1651536924073"
								tag="h1"
								className="w-100 h-auto valign-center px-96px text-text-on-primary ff-regular" >
								{t('apply.aboutMainText1651536924073')}
							</Text>
						</Group>
						<Group
							id="aboutColumnRightBottom1651536924073"
							direction="vertical"
							className="w-100 h-fill" >
							<Group
								id="aboutColumnRightBottomWrapper1651536924073"
								direction="vertical"
								className="w-100 h-auto valign-center position-absolute ps-96px pe-20" >
								<Text
									id="aboutColumnRightTopText1651536924073"
									tag="h3"
									className="w-100 h-auto valign-center text-primary-text ff-italic" >
									{t('apply.aboutColumnRightTopText1651536924073')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="aboutFooter1651536924073"
					direction="auto"
					className="w-100 h-auto bg-gray mt-64px" >
					<Group
						id="homeFooterLeft1651536924073"
						direction="vertical"
						className="min-w-320px w-fill h-auto px-16px py-48px" >
						<Image
							id="homeFooterLogo1651536924073"
							src={circles}
							className="w-160px h-auto valign-center" />
						<Text
							id="homeFooterCopyright1651536924073"
							className="w-100 h-auto mt-16px text-secondary-text ff-textMedium" >
							{t('apply.homeFooterCopyright1651536924073')}
						</Text>
						<Group
							id="homeFooterSocialIconsGroup1651536924073"
							direction="horizontal"
							className="w-100 h-auto mt-72px" >
							<Image
								id="homeFooterLinkedinButton1651536924073"
								onClick={homeFooterLinkedinButton1651536924073OnClick}
								src={ic_linkedin}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterInstagramButton1651536924073"
								onClick={homeFooterInstagramButton1651536924073OnClick}
								src={ic_instagram}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterWhatsappButton1651536924073"
								onClick={homeFooterWhatsappButton1651536924073OnClick}
								src={ic_whatsapp}
								className="w-52px h-auto me-32px" />
						</Group>
					</Group>
					<Group
						id="homeFooterRight1651536924073"
						direction="vertical"
						className="max-w-400px min-w-300px w-fill h-auto px-16px py-48px" >
						<Text
							id="homeFooterCompany1651536924073"
							className="w-100 h-auto ff-textBold" >
							{t('apply.homeFooterCompany1651536924073')}
						</Text>
						<Text
							id="homeFooterCookies1651536924073"
							onClick={homeFooterCookies1651536924073OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('apply.homeFooterCookies1651536924073')}
						</Text>
						<Text
							id="homeFooterPrivacy1651536924073"
							onClick={homeFooterPrivacy1651536924073OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('apply.homeFooterPrivacy1651536924073')}
						</Text>
						<Group
							id="homeFooterAddressGroup1651536924073"
							onClick={homeFooterAddressGroup1651536924073OnClick}
							direction="vertical"
							className="w-100 h-auto mt-32px" >
							<Text
								id="homeFooterAddress1651536924073"
								className="w-100 h-auto ff-textMedium" >
								{t('apply.homeFooterAddress1651536924073')}
							</Text>
							<Text
								id="homeFooterCity1651536924073"
								className="w-100 h-auto ff-textMedium" >
								{t('apply.homeFooterCity1651536924073')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="aboutFooterBottom1651536924073"
					direction="vertical"
					className="w-100 min-h-8px h-8px bg-primary" >
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
