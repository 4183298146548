import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import ic_whatsapp from '../assets/images/icons/ic_whatsapp.svg';
import ic_instagram from '../assets/images/icons/ic_instagram.svg';
import ic_linkedin from '../assets/images/icons/ic_linkedin.svg';
import soon from '../assets/images/icons/soon.svg';
import circles from '../assets/images/logos/circles.svg';
import { useTranslation } from 'react-i18next';

export const LocationsView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeFooterAddressGroup1651534271543OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.google.com\/maps\/place\/Pg.+de+la+Bonanova,+26,+08022+Barcelona\/@41.4050831,2.1281513,17z\/data=!3m1!4b1!4m5!3m4!1s0x12a498138a25248b:0x7ee63331cc4d1f98!8m2!3d41.4050831!4d2.13034","target":"_circlesLocation"}], options)
	}
	const homeFooterPrivacy1651534271543OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/app.mobincube.com\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterCookies1651534271543OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/doyo.tech\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterWhatsappButton1651534271543OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/wa.me\/555555555?text=Hi%20circles!","target":"_circlesInstagram"}], options)
	}
	const homeFooterInstagramButton1651534271543OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/instagram.com","target":"_circlesInstagram"}], options)
	}
	const homeFooterLinkedinButton1651534271543OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/linkedin.com","target":"_circlesLinkedin"}], options)
	}
	const homeHeaderApplyButton1651534271543OnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderSigninButton1651534271543OnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}
	const locHeaderMenuStayLabelOnClick = (options) => {
		run([{"function":"goto","view":"stay"}], options)
	}
	const locHeaderMenuHouseLabelOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeHeaderMenuAbout1651535007283OnClick = (options) => {
		run([{"function":"goto","view":"about"}], options)
	}
	const homeHeaderLogo1651534271543OnClick = (options) => {
		run([{"function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('locations.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="locationsBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 scrollable" >
				<Group
					id="locationsHeader"
					direction="auto"
					className="w-100 h-auto px-16px py-48px" >
					<Image
						id="homeHeaderLogo1651534271543"
						onClick={homeHeaderLogo1651534271543OnClick}
						src={circles}
						className="w-240px h-auto valign-center me-64px" />
					<Group
						id="homeHeaderMenu1651535007283"
						direction="auto"
						className="min-w-320px w-fill h-auto valign-center" >
						<Group
							id="locHeaderMenuAboutLabel"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="homeHeaderMenuAbout1651535007283"
								onClick={homeHeaderMenuAbout1651535007283OnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('locations.homeHeaderMenuAbout1651535007283')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651535007283"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="locHeaderMenuHouseLabel"
								onClick={locHeaderMenuHouseLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('locations.locHeaderMenuHouseLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651535007283"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="locHeaderMenuLocationsLabel"
								className="w-auto h-auto border-primary border-bottom px-6px pb-3px text-primary ff-textSemibold" >
								{t('locations.locHeaderMenuLocationsLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651535007283"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="locHeaderMenuStayLabel"
								onClick={locHeaderMenuStayLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('locations.locHeaderMenuStayLabel')}
							</Text>
						</Group>
					</Group>
					<Group
						id="homeHeaderButtons1651534271543"
						direction="horizontal"
						className="min-w-220px w-auto h-auto valign-center my-16px" >
						<Text
							id="homeHeaderSigninButton1651534271543"
							onClick={homeHeaderSigninButton1651534271543OnClick}
							className="w-auto h-auto pe-8px py-8px me-24px text-primary ff-regular" >
							{t('locations.homeHeaderSigninButton1651534271543')}
						</Text>
						<Text
							id="homeHeaderApplyButton1651534271543"
							onClick={homeHeaderApplyButton1651534271543OnClick}
							className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px text-text-on-primary ff-regular" >
							{t('locations.homeHeaderApplyButton1651534271543')}
						</Text>
					</Group>
				</Group>
				<Group
					id="locationsContent"
					direction="horizontal"
					className="w-100 min-h-90vh h-90vh" >
					<Group
						id="locationsContentWrapper"
						direction="vertical"
						className="w-100 h-auto valign-center" >
						<Group
							id="soonGroup"
							direction="vertical"
							className="w-auto h-auto align-center mt-52px" >
							<Text
								id="soonText"
								tag="h3"
								className="w-auto h-auto text-accent ff-regular" >
								{t('locations.soonText')}
							</Text>
							<Image
								id="component1651573435741"
								src={soon}
								className="w-160px h-auto ps-32px pt-16px" />
						</Group>
						<Group
							id="newsFormGroup"
							direction="vertical"
							className="w-auto h-auto align-center p-24px my-64px" >
							<Text
								id="newsFormTitle"
								tag="h1"
								className="w-auto h-auto text-primary-text ff-regular" >
								{t('locations.newsFormTitle')}
							</Text>
							<Text
								id="newsFormSubtitle"
								className="max-w-800px w-auto h-auto text-secondary-text ff-textMedium" >
								{t('locations.newsFormSubtitle')}
							</Text>
							<Group
								id="newsSubscribeInputGroup"
								direction="horizontal"
								className="w-100 h-auto border-top border-bottom border-start border-end border-radius-400px mt-48px" >
								<Input.Text
									id="newsSubscribeInputText"
									name="text"
									src="@property.email"
									className="w-fill min-h-100 h-100 border-top border-bottom border-start border-end valign-center px-24px" />
								<Text
									id="newsSubscribeButton"
									className="w-auto h-auto border-radius-400px bg-primary p-16px text-text-on-primary ff-regular" >
									{t('locations.newsSubscribeButton')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="locationsFooter"
					direction="auto"
					className="w-100 h-auto bg-gray mt-64px" >
					<Group
						id="homeFooterLeft1651534271543"
						direction="vertical"
						className="min-w-320px w-fill h-auto px-16px py-48px" >
						<Image
							id="homeFooterLogo1651534271543"
							src={circles}
							className="w-160px h-auto valign-center" />
						<Text
							id="homeFooterCopyright1651534271543"
							className="w-100 h-auto mt-16px text-secondary-text ff-textMedium" >
							{t('locations.homeFooterCopyright1651534271543')}
						</Text>
						<Group
							id="homeFooterSocialIconsGroup1651534271543"
							direction="horizontal"
							className="w-100 h-auto mt-72px" >
							<Image
								id="homeFooterLinkedinButton1651534271543"
								onClick={homeFooterLinkedinButton1651534271543OnClick}
								src={ic_linkedin}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterInstagramButton1651534271543"
								onClick={homeFooterInstagramButton1651534271543OnClick}
								src={ic_instagram}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterWhatsappButton1651534271543"
								onClick={homeFooterWhatsappButton1651534271543OnClick}
								src={ic_whatsapp}
								className="w-52px h-auto me-32px" />
						</Group>
					</Group>
					<Group
						id="homeFooterRight1651534271543"
						direction="vertical"
						className="max-w-400px min-w-300px w-fill h-auto px-16px py-48px" >
						<Text
							id="homeFooterCompany1651534271543"
							className="w-100 h-auto ff-textBold" >
							{t('locations.homeFooterCompany1651534271543')}
						</Text>
						<Text
							id="homeFooterCookies1651534271543"
							onClick={homeFooterCookies1651534271543OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('locations.homeFooterCookies1651534271543')}
						</Text>
						<Text
							id="homeFooterPrivacy1651534271543"
							onClick={homeFooterPrivacy1651534271543OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('locations.homeFooterPrivacy1651534271543')}
						</Text>
						<Group
							id="homeFooterAddressGroup1651534271543"
							onClick={homeFooterAddressGroup1651534271543OnClick}
							direction="vertical"
							className="w-100 h-auto mt-32px" >
							<Text
								id="homeFooterAddress1651534271543"
								className="w-100 h-auto ff-textMedium" >
								{t('locations.homeFooterAddress1651534271543')}
							</Text>
							<Text
								id="homeFooterCity1651534271543"
								className="w-100 h-auto ff-textMedium" >
								{t('locations.homeFooterCity1651534271543')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="locationsFooterBottom"
					direction="vertical"
					className="w-100 min-h-8px h-8px bg-primary" >
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
