import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Web } from '../components';
import { useTranslation } from 'react-i18next';

export const SignupView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('signup.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Web
				id="loginIframe1652608471369"
				src="https://customerportal.thehousemonk.com/login"
				className="w-100 min-h-100 fg-100" />
      </FullscreenWrapper>
    </>
  );
};
