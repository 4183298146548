import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, List } from '../components';
import ic_whatsapp from '../assets/images/icons/ic_whatsapp.svg';
import ic_instagram from '../assets/images/icons/ic_instagram.svg';
import ic_linkedin from '../assets/images/icons/ic_linkedin.svg';
import trans_64x64 from '../assets/trans_64x64.png';
import homemembershipsimage3 from '../assets/images/showroom/homemembershipsimage3.jpg';
import homemembershipsimage2 from '../assets/images/showroom/homemembershipsimage2.jpg';
import homemembershipsimage1 from '../assets/images/showroom/homemembershipsimage1.jpg';
import membership from '../assets/images/showroom/membership.jpg';
import wellbeing_perks from '../assets/images/showroom/wellbeing_perks.jpg';
import conscious_entrepeneurs from '../assets/images/showroom/conscious_entrepeneurs.jpg';
import corners_circles from '../assets/images/showroom/corners_circles.jpg';
import circles from '../assets/images/logos/circles.svg';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeFooterAddressGroupOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.google.com\/maps\/place\/Pg.+de+la+Bonanova,+26,+08022+Barcelona\/@41.4050831,2.1281513,17z\/data=!3m1!4b1!4m5!3m4!1s0x12a498138a25248b:0x7ee63331cc4d1f98!8m2!3d41.4050831!4d2.13034","target":"_circlesLocation"}], options)
	}
	const homeFooterPrivacyOnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/app.mobincube.com\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterCookiesOnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/doyo.tech\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterWhatsappButtonOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/wa.me\/555555555?text=Hi%20circles!","target":"_circlesInstagram"}], options)
	}
	const homeFooterInstagramButtonOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/instagram.com","target":"_circlesInstagram"}], options)
	}
	const homeFooterLinkedinButtonOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/linkedin.com","target":"_circlesLinkedin"}], options)
	}
	const homeMembershipsButton3OnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeMembershipsButton2OnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeMembershipsButton1OnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeBannerRightBottomRightButtonOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeBannerRightBottomLeftButtonOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeBannerRightTopButtonOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeBannerLeftButtonOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeHeaderApplyButtonOnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderSigninButtonOnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}
	const homeHeaderMenuStayLabelOnClick = (options) => {
		run([{"function":"goto","view":"stay"}], options)
	}
	const homeHeaderMenuLocationsLabelOnClick = (options) => {
		run([{"function":"goto","view":"spaces"}], options)
	}
	const homeHeaderMenuHouseLabelOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeHeaderMenuAboutLabelOnClick = (options) => {
		run([{"function":"goto","view":"about"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="homeBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 scrollable" >
				<Group
					id="homeHeader"
					direction="auto"
					className="w-100 h-auto px-16px py-48px" >
					<Image
						id="homeHeaderLogo"
						src={circles}
						className="w-240px h-auto valign-center me-64px" />
					<Group
						id="homeHeaderMenu"
						direction="auto"
						className="min-w-320px w-fill h-auto valign-center" >
						<Group
							id="homeHeaderMenuAbout1651534144241"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="homeHeaderMenuAboutLabel"
								onClick={homeHeaderMenuAboutLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('home.homeHeaderMenuAboutLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534144241"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="homeHeaderMenuHouseLabel"
								onClick={homeHeaderMenuHouseLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('home.homeHeaderMenuHouseLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534144241"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="homeHeaderMenuLocationsLabel"
								onClick={homeHeaderMenuLocationsLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('home.homeHeaderMenuLocationsLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534144241"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="homeHeaderMenuStayLabel"
								onClick={homeHeaderMenuStayLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('home.homeHeaderMenuStayLabel')}
							</Text>
						</Group>
					</Group>
					<Group
						id="homeHeaderButtons"
						direction="horizontal"
						className="min-w-220px w-auto h-auto valign-center my-16px" >
						<Text
							id="homeHeaderSigninButton"
							onClick={homeHeaderSigninButtonOnClick}
							className="w-auto h-auto pe-8px py-8px me-24px text-primary ff-regular" >
							{t('home.homeHeaderSigninButton')}
						</Text>
						<Text
							id="homeHeaderApplyButton"
							onClick={homeHeaderApplyButtonOnClick}
							className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px text-text-on-primary ff-regular" >
							{t('home.homeHeaderApplyButton')}
						</Text>
					</Group>
				</Group>
				<Group
					id="homeBanner"
					direction="auto"
					className="w-100 min-h-100vh h-auto" >
					<Group
						id="homeBannerLeft"
						direction="vertical"
						className="max-w-700px min-w-300px w-fill min-h-100 h-100 p-8px" >
						<Image
							id="homeBannerLeftImage"
							src={corners_circles}
							className="w-100 h-fill cover" />
						<Text
							id="homeBannerLeftButton"
							onClick={homeBannerLeftButtonOnClick}
							className="w-auto h-auto border-radius-50px align-end valign-end position-absolute-bottom bg-darken px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
							{t('home.homeBannerLeftButton')}
						</Text>
					</Group>
					<Group
						id="homeBannerRight"
						direction="vertical"
						className="min-w-300px w-fill min-h-100 h-100" >
						<Group
							id="homeBannerRightTop"
							direction="vertical"
							className="w-100 h-fill p-8px" >
							<Image
								id="homeBannerRightTopImage"
								src={conscious_entrepeneurs}
								className="w-100 h-fill cover" />
							<Text
								id="homeBannerRightTopButton"
								onClick={homeBannerRightTopButtonOnClick}
								className="w-auto h-auto border-radius-50px align-end valign-end position-absolute-bottom bg-darken px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
								{t('home.homeBannerRightTopButton')}
							</Text>
						</Group>
						<Group
							id="homeBannerRightBottom"
							direction="auto"
							className="w-100 h-fill" >
							<Group
								id="homeBannerRightBottomLeft"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill min-h-100 h-100 p-8px" >
								<Image
									id="homeBannerRightBottomLeftImage"
									src={wellbeing_perks}
									className="w-100 h-fill cover" />
								<Text
									id="homeBannerRightBottomLeftButton"
									onClick={homeBannerRightBottomLeftButtonOnClick}
									className="w-auto h-auto border-radius-50px align-end valign-end position-absolute-bottom bg-darken px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
									{t('home.homeBannerRightBottomLeftButton')}
								</Text>
							</Group>
							<Group
								id="homeBannerRightBottomRight"
								direction="vertical"
								className="min-w-300px w-fill min-h-100 h-100 p-8px" >
								<Image
									id="homeBannerRightBottomRightImage"
									src={membership}
									className="w-100 h-fill cover" />
								<Text
									id="homeBannerRightBottomRightButton"
									onClick={homeBannerRightBottomRightButtonOnClick}
									className="w-auto h-auto border-radius-50px align-end valign-end position-absolute-bottom bg-darken px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
									{t('home.homeBannerRightBottomRightButton')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeExperience"
					direction="vertical"
					className="w-100 h-auto" >
				</Group>
				<Group
					id="homeMemberships"
					direction="vertical"
					className="w-100 h-auto mt-72px" >
					<Text
						id="homeMembershipsTitle"
						tag="h1"
						className="w-100 h-auto p-16px ff-regular" >
						{t('home.homeMembershipsTitle')}
					</Text>
					<Group
						id="homeMembershipsImages"
						direction="auto"
						className="w-100 min-h-70vh h-70vh p-8px" >
						<Group
							id="homeMembershipsFrame1"
							direction="vertical"
							className="min-w-300px w-fill min-h-100 h-100 p-8px" >
							<Image
								id="homeMembershipsImage1"
								src={homemembershipsimage1}
								className="w-100 h-fill cover" />
							<Text
								id="homeMembershipsButton1"
								onClick={homeMembershipsButton1OnClick}
								className="w-auto h-auto border-radius-50px align-center valign-end position-absolute-bottom bg-primary px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
								{t('home.homeMembershipsButton1')}
							</Text>
						</Group>
						<Group
							id="homeMembershipsFrame2"
							direction="vertical"
							className="max-w-450px min-w-300px w-fill min-h-100 h-100 p-8px" >
							<Image
								id="homeMembershipsImage2"
								src={homemembershipsimage2}
								className="w-100 h-fill cover" />
							<Text
								id="homeMembershipsButton2"
								onClick={homeMembershipsButton2OnClick}
								className="w-auto h-auto border-radius-50px align-center valign-end position-absolute-bottom bg-primary px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
								{t('home.homeMembershipsButton2')}
							</Text>
						</Group>
						<Group
							id="homeMembershipsFrame3"
							direction="vertical"
							className="max-w-450px min-w-300px w-fill min-h-100 h-100 p-8px" >
							<Image
								id="homeMembershipsImage3"
								src={homemembershipsimage3}
								className="w-100 h-fill cover" />
							<Text
								id="homeMembershipsButton3"
								onClick={homeMembershipsButton3OnClick}
								className="w-auto h-auto border-radius-50px align-center valign-end position-absolute-bottom bg-primary px-16px py-8px me-32px mb-32px text-text-on-primary ff-regular" >
								{t('home.homeMembershipsButton3')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeNews"
					direction="vertical"
					className="w-100 h-auto py-16px mt-72px" >
					<Text
						id="homeNewsTitle"
						tag="h1"
						className="w-100 h-auto p-16px ff-regular" >
						{t('home.homeNewsTitle')}
					</Text>
					<List
						id="homeNewsList"
						direction="horizontal"
						src="@firebase.firestore.news"
						order={{"type":"desc","field":"date"}}
						className="w-100 min-h-400px h-400px" >
						<Group
							id="homeNewsListCell"
							direction="vertical"
							className="w-500px min-h-100 h-100" >
							<Image
								id="homeNewsImage"
								fallback={trans_64x64}
								src="photo"
								className="w-100 min-h-100 fg-100 cover" />
							<Group
								id="homeNewsCellContent"
								direction="vertical"
								className="w-100 min-h-100 fg-100 position-absolute bg-transparent-to-primary-text" >
								<Group
									id="homeNewsCellData"
									direction="vertical"
									className="w-100 h-auto valign-end position-absolute-bottom p-24px" >
									<Text
										id="homeNewsCellDate"
										format={{"type":"date","day":"2-digit","month":"short","year":"numeric"}}
										tag="h3"
										src="date"
										className="w-100 h-auto text-text-on-primary ff-textMedium" >
										{t('home.homeNewsCellDate')}
									</Text>
									<Text
										id="homeNewsCellTitle"
										tag="h3"
										src="title"
										className="w-100 h-auto mt-4px text-text-on-primary ff-textBold" >
										{t('home.homeNewsCellTitle')}
									</Text>
									<Text
										id="homeNewsCellDesc"
										tag="small"
										src="description"
										className="w-100 h-auto mt-4px text-text-on-primary ff-textMedium" >
										{t('home.homeNewsCellDesc')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
				<Group
					id="homeFooter"
					direction="auto"
					className="w-100 h-auto bg-gray mt-64px" >
					<Group
						id="homeFooterLeft"
						direction="vertical"
						className="min-w-320px w-fill h-auto px-16px py-48px" >
						<Image
							id="homeFooterLogo"
							src={circles}
							className="w-160px h-auto valign-center" />
						<Text
							id="homeFooterCopyright"
							className="w-100 h-auto mt-16px text-secondary-text ff-textMedium" >
							{t('home.homeFooterCopyright')}
						</Text>
						<Group
							id="homeFooterSocialIconsGroup"
							direction="horizontal"
							className="w-100 h-auto mt-72px" >
							<Image
								id="homeFooterLinkedinButton"
								onClick={homeFooterLinkedinButtonOnClick}
								src={ic_linkedin}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterInstagramButton"
								onClick={homeFooterInstagramButtonOnClick}
								src={ic_instagram}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterWhatsappButton"
								onClick={homeFooterWhatsappButtonOnClick}
								src={ic_whatsapp}
								className="w-52px h-auto me-32px" />
						</Group>
					</Group>
					<Group
						id="homeFooterRight"
						direction="vertical"
						className="max-w-400px min-w-300px w-fill h-auto px-16px py-48px" >
						<Text
							id="homeFooterCompany"
							className="w-100 h-auto ff-textBold" >
							{t('home.homeFooterCompany')}
						</Text>
						<Text
							id="homeFooterCookies"
							onClick={homeFooterCookiesOnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('home.homeFooterCookies')}
						</Text>
						<Text
							id="homeFooterPrivacy"
							onClick={homeFooterPrivacyOnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('home.homeFooterPrivacy')}
						</Text>
						<Group
							id="homeFooterAddressGroup"
							onClick={homeFooterAddressGroupOnClick}
							direction="vertical"
							className="w-100 h-auto mt-32px" >
							<Text
								id="homeFooterAddress"
								className="w-100 h-auto ff-textMedium" >
								{t('home.homeFooterAddress')}
							</Text>
							<Text
								id="homeFooterCity"
								className="w-100 h-auto ff-textMedium" >
								{t('home.homeFooterCity')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFooterBottom"
					direction="vertical"
					className="w-100 min-h-8px h-8px bg-primary" >
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
