import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { AboutView, ApplyView, Apply_formView, HomeView, HouseView, LocationsView, LoginView, SignupView, SpacesView, StayView } from './pages';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={HomeView} />
						<Route exact path="/about" component={AboutView} />
<Route exact path="/about/:params(.+)" component={AboutView} />
						<Route exact path="/apply" component={ApplyView} />
						<Route exact path="/apply/:params(.+)" component={ApplyView} />
						<Route exact path="/apply_form" component={Apply_formView} />
						<Route exact path="/apply_form/:params(.+)" component={Apply_formView} />
						<Route exact path="/home" component={HomeView} />
						<Route exact path="/home/:params(.+)" component={HomeView} />
						<Route exact path="/house" component={HouseView} />
						<Route exact path="/house/:params(.+)" component={HouseView} />
						<Route exact path="/locations" component={LocationsView} />
						<Route exact path="/locations/:params(.+)" component={LocationsView} />
						<Route exact path="/login" component={LoginView} />
						<Route exact path="/login/:params(.+)" component={LoginView} />
						<Route exact path="/signup" component={SignupView} />
						<Route exact path="/signup/:params(.+)" component={SignupView} />
						<Route exact path="/spaces" component={SpacesView} />
						<Route exact path="/spaces/:params(.+)" component={SpacesView} />
						<Route exact path="/stay" component={StayView} />
						<Route exact path="/stay/:params(.+)" component={StayView} />
						<Route exact path="*" component={HomeView} />
						
          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
