import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text } from '../components';
import ic_whatsapp from '../assets/images/icons/ic_whatsapp.svg';
import ic_instagram from '../assets/images/icons/ic_instagram.svg';
import ic_linkedin from '../assets/images/icons/ic_linkedin.svg';
import corners_plants from '../assets/images/showroom/corners_plants.jpg';
import circles from '../assets/images/logos/circles.svg';
import { useTranslation } from 'react-i18next';

export const AboutView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeFooterAddressGroup1651531304373OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.google.com\/maps\/place\/Pg.+de+la+Bonanova,+26,+08022+Barcelona\/@41.4050831,2.1281513,17z\/data=!3m1!4b1!4m5!3m4!1s0x12a498138a25248b:0x7ee63331cc4d1f98!8m2!3d41.4050831!4d2.13034","target":"_circlesLocation"}], options)
	}
	const homeFooterPrivacy1651531304373OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/app.mobincube.com\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterCookies1651531304373OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/doyo.tech\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterWhatsappButton1651531304373OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/wa.me\/555555555?text=Hi%20circles!","target":"_circlesInstagram"}], options)
	}
	const homeFooterInstagramButton1651531304373OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/instagram.com","target":"_circlesInstagram"}], options)
	}
	const homeFooterLinkedinButton1651531304373OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/linkedin.com","target":"_circlesLinkedin"}], options)
	}
	const homeHeaderApplyButton1651531304373OnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderSigninButton1651531304373OnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}
	const homeHeaderMenuAbout1651534088038OnClick = (options) => {
		run([{"function":"goto","view":"stay"}], options)
	}
	const aboutHeaderMenuLocationsLabelOnClick = (options) => {
		run([{"function":"goto","view":"locations"}], options)
	}
	const aboutHeaderMenuHouseLabelOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const homeHeaderLogo1651531304373OnClick = (options) => {
		run([{"function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('about.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="aboutBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 scrollable" >
				<Group
					id="aboutHeader"
					direction="auto"
					className="w-100 h-auto px-16px py-48px" >
					<Image
						id="homeHeaderLogo1651531304373"
						onClick={homeHeaderLogo1651531304373OnClick}
						src={circles}
						className="w-240px h-auto valign-center me-64px" />
					<Group
						id="homeHeaderMenu1651531304373"
						direction="auto"
						className="min-w-320px w-fill h-auto valign-center" >
						<Group
							id="homeHeaderMenuAbout1651531304373"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="aboutHeaderMenuAboutLabel"
								className="w-auto h-auto border-primary border-bottom px-6px pb-3px text-primary ff-textSemibold" >
								{t('about.aboutHeaderMenuAboutLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651533988505"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="aboutHeaderMenuHouseLabel"
								onClick={aboutHeaderMenuHouseLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('about.aboutHeaderMenuHouseLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534059402"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="aboutHeaderMenuLocationsLabel"
								onClick={aboutHeaderMenuLocationsLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('about.aboutHeaderMenuLocationsLabel')}
							</Text>
						</Group>
						<Group
							id="aboutHeaderMenuStayLabel"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="homeHeaderMenuAbout1651534088038"
								onClick={homeHeaderMenuAbout1651534088038OnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('about.homeHeaderMenuAbout1651534088038')}
							</Text>
						</Group>
					</Group>
					<Group
						id="homeHeaderButtons1651531304373"
						direction="horizontal"
						className="min-w-220px w-auto h-auto valign-center my-16px" >
						<Text
							id="homeHeaderSigninButton1651531304373"
							onClick={homeHeaderSigninButton1651531304373OnClick}
							className="w-auto h-auto pe-8px py-8px me-24px text-primary ff-regular" >
							{t('about.homeHeaderSigninButton1651531304373')}
						</Text>
						<Text
							id="homeHeaderApplyButton1651531304373"
							onClick={homeHeaderApplyButton1651531304373OnClick}
							className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px text-text-on-primary ff-regular" >
							{t('about.homeHeaderApplyButton1651531304373')}
						</Text>
					</Group>
				</Group>
				<Group
					id="aboutContent"
					direction="auto"
					className="w-100 h-auto" >
					<Group
						id="aboutColumnLeft"
						direction="horizontal"
						className="max-w-24px w-fill min-h-100 h-100" >
						<Group
							id="aboutColumnLeftBar"
							direction="vertical"
							className="w-100 min-h-160px h-160px valign-center bg-primary" >
						</Group>
					</Group>
					<Group
						id="aboutColumnCenter"
						direction="horizontal"
						className="w-auto min-h-100 h-100" >
						<Image
							id="component1651531826907"
							src={corners_plants}
							className="w-auto min-h-100vh h-100vh" />
					</Group>
					<Group
						id="aboutColumnRight"
						direction="vertical"
						className="w-fill min-h-100 h-100" >
						<Group
							id="aboutColumnRightTop"
							direction="vertical"
							className="w-100 h-fill" >
							<Group
								id="aboutColumnRightTopWrapper"
								direction="vertical"
								className="w-100 h-auto valign-center position-absolute ps-96px pe-20" >
								<Text
									id="aboutColumnRightTopTitle"
									tag="h1"
									className="w-100 h-auto valign-center text-primary-text ff-regular" >
									{t('about.aboutColumnRightTopTitle')}
								</Text>
								<Text
									id="aboutColumnRightTopText"
									className="w-85 h-auto mt-32px text-primary-text ff-textMedium" >
									{t('about.aboutColumnRightTopText')}
								</Text>
								<Text
									id="aboutColumnRightTopText2"
									className="w-100 h-auto mt-16px text-primary-text ff-textMedium" >
									{t('about.aboutColumnRightTopText2')}
								</Text>
							</Group>
						</Group>
						<Group
							id="aboutColumnRightBar"
							direction="horizontal"
							className="w-100 min-h-160px h-160px bg-primary" >
							<Text
								id="aboutMainText"
								tag="h1"
								className="w-100 h-auto valign-center px-96px text-text-on-primary ff-regular" >
								{t('about.aboutMainText')}
							</Text>
						</Group>
						<Group
							id="aboutColumnRightBottom"
							direction="vertical"
							className="w-100 h-fill" >
							<Group
								id="aboutColumnRightBottomWrapper"
								direction="vertical"
								className="w-100 h-auto valign-center position-absolute ps-96px pe-20" >
								<Text
									id="aboutColumnRightBottomText"
									tag="h3"
									className="w-100 h-auto valign-center text-primary-text ff-italic" >
									{t('about.aboutColumnRightBottomText')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="aboutFooter"
					direction="auto"
					className="w-100 h-auto bg-gray mt-64px" >
					<Group
						id="homeFooterLeft1651531304373"
						direction="vertical"
						className="min-w-320px w-fill h-auto px-16px py-48px" >
						<Image
							id="homeFooterLogo1651531304373"
							src={circles}
							className="w-160px h-auto valign-center" />
						<Text
							id="homeFooterCopyright1651531304373"
							className="w-100 h-auto mt-16px text-secondary-text ff-textMedium" >
							{t('about.homeFooterCopyright1651531304373')}
						</Text>
						<Group
							id="homeFooterSocialIconsGroup1651531304373"
							direction="horizontal"
							className="w-100 h-auto mt-72px" >
							<Image
								id="homeFooterLinkedinButton1651531304373"
								onClick={homeFooterLinkedinButton1651531304373OnClick}
								src={ic_linkedin}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterInstagramButton1651531304373"
								onClick={homeFooterInstagramButton1651531304373OnClick}
								src={ic_instagram}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterWhatsappButton1651531304373"
								onClick={homeFooterWhatsappButton1651531304373OnClick}
								src={ic_whatsapp}
								className="w-52px h-auto me-32px" />
						</Group>
					</Group>
					<Group
						id="homeFooterRight1651531304373"
						direction="vertical"
						className="max-w-400px min-w-300px w-fill h-auto px-16px py-48px" >
						<Text
							id="homeFooterCompany1651531304373"
							className="w-100 h-auto ff-textBold" >
							{t('about.homeFooterCompany1651531304373')}
						</Text>
						<Text
							id="homeFooterCookies1651531304373"
							onClick={homeFooterCookies1651531304373OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('about.homeFooterCookies1651531304373')}
						</Text>
						<Text
							id="homeFooterPrivacy1651531304373"
							onClick={homeFooterPrivacy1651531304373OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('about.homeFooterPrivacy1651531304373')}
						</Text>
						<Group
							id="homeFooterAddressGroup1651531304373"
							onClick={homeFooterAddressGroup1651531304373OnClick}
							direction="vertical"
							className="w-100 h-auto mt-32px" >
							<Text
								id="homeFooterAddress1651531304373"
								className="w-100 h-auto ff-textMedium" >
								{t('about.homeFooterAddress1651531304373')}
							</Text>
							<Text
								id="homeFooterCity1651531304373"
								className="w-100 h-auto ff-textMedium" >
								{t('about.homeFooterCity1651531304373')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="aboutFooterBottom"
					direction="vertical"
					className="w-100 min-h-8px h-8px bg-primary" >
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
