import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text } from '../components';
import ic_whatsapp from '../assets/images/icons/ic_whatsapp.svg';
import ic_instagram from '../assets/images/icons/ic_instagram.svg';
import ic_linkedin from '../assets/images/icons/ic_linkedin.svg';
import people from '../assets/images/showroom/people.jpg';
import circles_coach from '../assets/images/showroom/circles_coach.jpg';
import stay_top_right from '../assets/images/showroom/stay_top_right.jpg';
import stay_top_left from '../assets/images/showroom/stay_top_left.jpg';
import circles from '../assets/images/logos/circles.svg';
import { useTranslation } from 'react-i18next';

export const StayView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeFooterAddressGroup1651534302998OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.google.com\/maps\/place\/Pg.+de+la+Bonanova,+26,+08022+Barcelona\/@41.4050831,2.1281513,17z\/data=!3m1!4b1!4m5!3m4!1s0x12a498138a25248b:0x7ee63331cc4d1f98!8m2!3d41.4050831!4d2.13034","target":"_circlesLocation"}], options)
	}
	const homeFooterPrivacy1651534302998OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/app.mobincube.com\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterCookies1651534302998OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/doyo.tech\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterWhatsappButton1651534302998OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/wa.me\/555555555?text=Hi%20circles!","target":"_circlesInstagram"}], options)
	}
	const homeFooterInstagramButton1651534302998OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/instagram.com","target":"_circlesInstagram"}], options)
	}
	const homeFooterLinkedinButton1651534302998OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/linkedin.com","target":"_circlesLinkedin"}], options)
	}
	const staySection3ButtonOnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const staySection2ButtonOnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const staySection1ButtonOnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderApplyButton1651534302998OnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderSigninButton1651534302998OnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}
	const stayHeaderMenuLocationsLabelOnClick = (options) => {
		run([{"function":"goto","view":"locations"}], options)
	}
	const stayHeaderMenuHouseLabelOnClick = (options) => {
		run([{"function":"goto","view":"house"}], options)
	}
	const stayHeaderMenuAboutLabelOnClick = (options) => {
		run([{"function":"goto","view":"about"}], options)
	}
	const stayHeaderLogoOnClick = (options) => {
		run([{"function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stay.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="stayBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 scrollable" >
				<Group
					id="stayHeader"
					direction="auto"
					className="w-100 h-auto px-16px py-48px" >
					<Image
						id="stayHeaderLogo"
						onClick={stayHeaderLogoOnClick}
						src={circles}
						className="w-240px h-auto valign-center me-64px" />
					<Group
						id="stayHeaderMenu"
						direction="auto"
						className="min-w-320px w-fill h-auto valign-center" >
						<Group
							id="homeHeaderMenuAbout1651535026664"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="stayHeaderMenuAboutLabel"
								onClick={stayHeaderMenuAboutLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('stay.stayHeaderMenuAboutLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651535026664"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="stayHeaderMenuHouseLabel"
								onClick={stayHeaderMenuHouseLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('stay.stayHeaderMenuHouseLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651535026664"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="stayHeaderMenuLocationsLabel"
								onClick={stayHeaderMenuLocationsLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('stay.stayHeaderMenuLocationsLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651535026664"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="stayHeaderMenuStayLabel"
								className="w-auto h-auto border-primary border-bottom px-6px pb-3px text-primary ff-textSemibold" >
								{t('stay.stayHeaderMenuStayLabel')}
							</Text>
						</Group>
					</Group>
					<Group
						id="stayHeaderButtons"
						direction="horizontal"
						className="min-w-220px w-auto h-auto valign-center my-16px" >
						<Text
							id="homeHeaderSigninButton1651534302998"
							onClick={homeHeaderSigninButton1651534302998OnClick}
							className="w-auto h-auto pe-8px py-8px me-24px text-primary ff-regular" >
							{t('stay.homeHeaderSigninButton1651534302998')}
						</Text>
						<Text
							id="homeHeaderApplyButton1651534302998"
							onClick={homeHeaderApplyButton1651534302998OnClick}
							className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px text-text-on-primary ff-regular" >
							{t('stay.homeHeaderApplyButton1651534302998')}
						</Text>
					</Group>
				</Group>
				<Group
					id="stayContent"
					direction="vertical"
					className="w-100 h-auto" >
					<Group
						id="stayBanner"
						direction="vertical"
						className="w-100 min-h-100vh h-100vh" >
						<Group
							id="stayBannerPhotos"
							direction="horizontal"
							className="w-100 h-fill" >
							<Image
								id="stayBannerPhotoLeft"
								src={stay_top_left}
								className="w-fill min-h-100 h-100 cover" />
							<Image
								id="stayBannerPhotoRight"
								src={stay_top_right}
								className="w-fill min-h-100 h-100 cover" />
						</Group>
						<Group
							id="stayBannerBar"
							direction="horizontal"
							className="w-100 min-h-160px h-160px bg-primary" >
							<Text
								id="stayBannerText"
								tag="h1"
								className="w-100 h-auto valign-center text-text-on-primary ff-regular text-center" >
								{t('stay.stayBannerText')}
							</Text>
						</Group>
					</Group>
					<Text
						id="stayIntroText"
						tag="h2"
						className="w-50 h-auto align-center mt-48px text-primary-text ff-textMedium text-center" >
						{t('stay.stayIntroText')}
					</Text>
					<Group
						id="staySection1"
						direction="vertical"
						className="w-100 h-auto mt-48px" >
						<Text
							id="staySection1Title"
							tag="h1"
							className="w-100 h-auto p-24px text-primary-text ff-regular" >
							{t('stay.staySection1Title')}
						</Text>
						<Group
							id="staySection1Content"
							direction="auto"
							className="w-100 h-auto bg-gray" >
							<Image
								id="staySection1Image"
								src={circles_coach}
								className="max-w-1200px min-w-300px w-fill min-h-500px h-500px cover" />
							<Group
								id="staySection1Content"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-64px" >
								<Text
									id="staySection1Text"
									className="w-fill h-auto align-center text-primary-text ff-textMedium" >
									{t('stay.staySection1Text')}
								</Text>
								<Text
									id="staySection1Button"
									onClick={staySection1ButtonOnClick}
									className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px mt-48px text-text-on-primary ff-regular" >
									{t('stay.staySection1Button')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="staySection2"
						direction="vertical"
						className="w-100 h-auto mt-48px" >
						<Text
							id="staySection2Title"
							tag="h1"
							className="w-100 h-auto p-24px text-primary-text ff-regular" >
							{t('stay.staySection2Title')}
						</Text>
						<Group
							id="staySection2Content"
							direction="auto"
							className="w-100 h-auto bg-gray" >
							<Group
								id="staySection2Content"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-64px" >
								<Text
									id="staySection2Text"
									className="w-fill h-auto align-center text-primary-text ff-textMedium" >
									{t('stay.staySection2Text')}
								</Text>
								<Text
									id="staySection2Button"
									onClick={staySection2ButtonOnClick}
									className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px mt-48px text-text-on-primary ff-regular" >
									{t('stay.staySection2Button')}
								</Text>
							</Group>
							<Image
								id="staySection2Image"
								src={people}
								className="max-w-1200px min-w-300px w-fill min-h-500px h-500px cover" />
						</Group>
					</Group>
					<Group
						id="staySection3"
						direction="vertical"
						className="w-100 h-auto mt-48px" >
						<Text
							id="staySection3Title"
							tag="h1"
							className="w-100 h-auto p-24px text-primary-text ff-regular" >
							{t('stay.staySection3Title')}
						</Text>
						<Group
							id="staySection3Content"
							direction="auto"
							className="w-100 h-auto bg-gray" >
							<Image
								id="staySection3Image"
								src={circles_coach}
								className="max-w-1200px min-w-300px w-fill min-h-500px h-500px cover" />
							<Group
								id="staySection3Content"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-64px" >
								<Text
									id="staySection3Text"
									className="w-fill h-auto align-center text-primary-text ff-textMedium" >
									{t('stay.staySection3Text')}
								</Text>
								<Text
									id="staySection3Button"
									onClick={staySection3ButtonOnClick}
									className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px mt-48px text-text-on-primary ff-regular" >
									{t('stay.staySection3Button')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="stayFooter"
					direction="auto"
					className="w-100 h-auto bg-gray mt-64px" >
					<Group
						id="homeFooterLeft1651534302998"
						direction="vertical"
						className="min-w-320px w-fill h-auto px-16px py-48px" >
						<Image
							id="homeFooterLogo1651534302998"
							src={circles}
							className="w-160px h-auto valign-center" />
						<Text
							id="homeFooterCopyright1651534302998"
							className="w-100 h-auto mt-16px text-secondary-text ff-textMedium" >
							{t('stay.homeFooterCopyright1651534302998')}
						</Text>
						<Group
							id="homeFooterSocialIconsGroup1651534302998"
							direction="horizontal"
							className="w-100 h-auto mt-72px" >
							<Image
								id="homeFooterLinkedinButton1651534302998"
								onClick={homeFooterLinkedinButton1651534302998OnClick}
								src={ic_linkedin}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterInstagramButton1651534302998"
								onClick={homeFooterInstagramButton1651534302998OnClick}
								src={ic_instagram}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterWhatsappButton1651534302998"
								onClick={homeFooterWhatsappButton1651534302998OnClick}
								src={ic_whatsapp}
								className="w-52px h-auto me-32px" />
						</Group>
					</Group>
					<Group
						id="homeFooterRight1651534302998"
						direction="vertical"
						className="max-w-400px min-w-300px w-fill h-auto px-16px py-48px" >
						<Text
							id="homeFooterCompany1651534302998"
							className="w-100 h-auto ff-textBold" >
							{t('stay.homeFooterCompany1651534302998')}
						</Text>
						<Text
							id="homeFooterCookies1651534302998"
							onClick={homeFooterCookies1651534302998OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('stay.homeFooterCookies1651534302998')}
						</Text>
						<Text
							id="homeFooterPrivacy1651534302998"
							onClick={homeFooterPrivacy1651534302998OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('stay.homeFooterPrivacy1651534302998')}
						</Text>
						<Group
							id="homeFooterAddressGroup1651534302998"
							onClick={homeFooterAddressGroup1651534302998OnClick}
							direction="vertical"
							className="w-100 h-auto mt-32px" >
							<Text
								id="homeFooterAddress1651534302998"
								className="w-100 h-auto ff-textMedium" >
								{t('stay.homeFooterAddress1651534302998')}
							</Text>
							<Text
								id="homeFooterCity1651534302998"
								className="w-100 h-auto ff-textMedium" >
								{t('stay.homeFooterCity1651534302998')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="stayFooterBottom"
					direction="vertical"
					className="w-100 min-h-8px h-8px bg-primary" >
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
