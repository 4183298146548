import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Video } from '../components';
import ic_whatsapp from '../assets/images/icons/ic_whatsapp.svg';
import ic_instagram from '../assets/images/icons/ic_instagram.svg';
import ic_linkedin from '../assets/images/icons/ic_linkedin.svg';
import wellbeing_perks from '../assets/images/showroom/wellbeing_perks.jpg';
import conscious_entrepeneurs from '../assets/images/showroom/conscious_entrepeneurs.jpg';
import bedroom from '../assets/images/showroom/bedroom.jpg';
import chair from '../assets/images/showroom/chair.jpg';
import lamp from '../assets/images/showroom/lamp.jpg';
import glasses from '../assets/images/showroom/glasses.jpg';
import corners_plants from '../assets/images/showroom/corners_plants.jpg';
import trans_64x64 from '../assets/trans_64x64.png';
import sketch from '../assets/images/sketch.png';
import circles_coach from '../assets/images/showroom/circles_coach.jpg';
import circleshouse_0ccbc1700b215adc58126451b1e0b04a from '../assets/media/circleshouse_0ccbc1700b215adc58126451b1e0b04a.mp4';
import circles from '../assets/images/logos/circles.svg';
import { useTranslation } from 'react-i18next';

export const HouseView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeFooterAddressGroup1651534256877OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.google.com\/maps\/place\/Pg.+de+la+Bonanova,+26,+08022+Barcelona\/@41.4050831,2.1281513,17z\/data=!3m1!4b1!4m5!3m4!1s0x12a498138a25248b:0x7ee63331cc4d1f98!8m2!3d41.4050831!4d2.13034","target":"_circlesLocation"}], options)
	}
	const homeFooterPrivacy1651534256877OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/app.mobincube.com\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterCookies1651534256877OnClick = (options) => {
		run([{"function":"set","what":"@property.policyURL","value":"https:\/\/doyo.tech\/legal\/terms"},{"function":"popup","dialog":"policy_dialog"}], options)
	}
	const homeFooterWhatsappButton1651534256877OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/wa.me\/555555555?text=Hi%20circles!","target":"_circlesInstagram"}], options)
	}
	const homeFooterInstagramButton1651534256877OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/instagram.com","target":"_circlesInstagram"}], options)
	}
	const homeFooterLinkedinButton1651534256877OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/linkedin.com","target":"_circlesLinkedin"}], options)
	}
	const houseRitualsApplyOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/tel4v0e92ku.typeform.com\/to\/t32RvR3F?typeform-source=circleshouse.godaddysites.com"}], options)
	}
	const homeHeaderApplyButton1651534256877OnClick = (options) => {
		run([{"function":"goto","view":"apply_form"}], options)
	}
	const homeHeaderSigninButton1651534256877OnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}
	const houseHeaderMenuStayLabelOnClick = (options) => {
		run([{"function":"goto","view":"stay"}], options)
	}
	const houseHeaderMenuLocationsLabelOnClick = (options) => {
		run([{"function":"goto","view":"locations"}], options)
	}
	const houseHeaderMenuAboutLabelOnClick = (options) => {
		run([{"function":"goto","view":"about"}], options)
	}
	const homeHeaderLogo1651534256877OnClick = (options) => {
		run([{"function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('house.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="houseBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 scrollable" >
				<Group
					id="houseHeader"
					direction="auto"
					className="w-100 h-auto px-16px py-48px" >
					<Image
						id="homeHeaderLogo1651534256877"
						onClick={homeHeaderLogo1651534256877OnClick}
						src={circles}
						className="w-240px h-auto valign-center me-64px" />
					<Group
						id="houseHeaderMenu"
						direction="auto"
						className="min-w-320px w-fill h-auto valign-center" >
						<Group
							id="houseHeaderMenuAbout"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="houseHeaderMenuAboutLabel"
								onClick={houseHeaderMenuAboutLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('house.houseHeaderMenuAboutLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534956719"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="houseHeaderMenuHouseLabel"
								className="w-auto h-auto border-primary border-bottom px-6px pb-3px text-primary ff-textSemibold" >
								{t('house.houseHeaderMenuHouseLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534956719"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="houseHeaderMenuLocationsLabel"
								onClick={houseHeaderMenuLocationsLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('house.houseHeaderMenuLocationsLabel')}
							</Text>
						</Group>
						<Group
							id="homeHeaderMenuAbout1651534956719"
							direction="vertical"
							className="w-auto h-auto border-gray border-end pe-24px py-2px me-24px my-16px" >
							<Text
								id="houseHeaderMenuStayLabel"
								onClick={houseHeaderMenuStayLabelOnClick}
								className="w-auto h-auto px-6px pb-3px text-primary-text ff-textSemibold" >
								{t('house.houseHeaderMenuStayLabel')}
							</Text>
						</Group>
					</Group>
					<Group
						id="homeHeaderButtons1651534256877"
						direction="horizontal"
						className="min-w-220px w-auto h-auto valign-center my-16px" >
						<Text
							id="homeHeaderSigninButton1651534256877"
							onClick={homeHeaderSigninButton1651534256877OnClick}
							className="w-auto h-auto pe-8px py-8px me-24px text-primary ff-regular" >
							{t('house.homeHeaderSigninButton1651534256877')}
						</Text>
						<Text
							id="homeHeaderApplyButton1651534256877"
							onClick={homeHeaderApplyButton1651534256877OnClick}
							className="w-auto h-auto border-radius-50px bg-primary px-16px py-8px text-text-on-primary ff-regular" >
							{t('house.homeHeaderApplyButton1651534256877')}
						</Text>
					</Group>
				</Group>
				<Group
					id="houseContent"
					direction="auto"
					className="w-100 h-auto" >
					<Group
						id="houseBannerGroup"
						direction="vertical"
						className="w-100 h-auto" >
						<Video
							id="houseBannerVideo"
							src={circleshouse_0ccbc1700b215adc58126451b1e0b04a}
						autoPlay={true}
						controls
						loop
							className="w-100 h-auto cover" />
					</Group>
					<Group
						id="houseOpeningGroup"
						direction="auto"
						className="w-100 h-auto mt-48px" >
						<Image
							id="houseOpeningLeft"
							src={circles_coach}
							className="min-w-300px w-fill min-h-100 h-100 cover" />
						<Group
							id="houseOpeningRight"
							direction="vertical"
							className="min-w-300px w-fill h-auto p-24px" >
							<Text
								id="houseOpeningTitle"
								tag="h1"
								className="w-100 h-auto text-primary-text ff-regular" >
								{t('house.houseOpeningTitle')}
							</Text>
							<Text
								id="houseOpeningDescription"
								className="w-100 h-auto text-primary-text ff-textMedium" >
								{t('house.houseOpeningDescription')}
							</Text>
							<Group
								id="houseOpeningSamplesGroup"
								direction="vertical"
								className="w-100 h-auto mt-32px" >
								<Image
									id="component1651576292606"
									src={sketch}
									className="w-100 h-auto position-absolute mt-32px" />
								<Group
									id="houseOpeningCircle"
									direction="vertical"
									className="w-75 h-auto border-top border-bottom border-start border-end border-radius-500px align-center" >
									<Image
										id="component1651583246511"
										src={trans_64x64}
										className="w-100 h-auto" />
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="houseGallery"
						direction="auto"
						className="w-100 h-auto" >
						<Group
							id="houseGalleryLeft"
							direction="auto"
							className="min-w-300px w-fill h-auto" >
							<Image
								id="houseGalleryLeftLeft"
								src={corners_plants}
								className="w-fill min-h-500px h-500px p-8px cover" />
							<Image
								id="houseGalleryLeftRight"
								src={glasses}
								className="w-fill min-h-500px h-500px p-8px cover" />
						</Group>
						<Group
							id="houseGalleryRight"
							direction="auto"
							className="min-w-300px w-fill h-auto" >
							<Image
								id="houseGalleryRightLeft"
								src={lamp}
								className="w-fill min-h-500px h-500px p-8px cover" />
							<Image
								id="houseGalleryRightRight"
								src={chair}
								className="w-fill min-h-500px h-500px p-8px cover" />
						</Group>
					</Group>
					<Group
						id="houseExperience"
						direction="vertical"
						className="w-100 h-auto mt-48px" >
						<Text
							id="houseExperienceTitle"
							tag="h1"
							className="w-100 h-auto p-24px text-primary-text ff-regular text-center" >
							{t('house.houseExperienceTitle')}
						</Text>
						<Group
							id="houseExperienceGroup"
							direction="auto"
							className="w-100 h-auto" >
							<Group
								id="houseExperienceLeft"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-8px" >
								<Text
									id="houseExperienceLeftTitle"
									tag="h3"
									className="w-100 h-auto text-primary-text ff-regular" >
									{t('house.houseExperienceLeftTitle')}
								</Text>
								<Group
									id="houseExperienceLeftImageGroup"
									direction="horizontal"
									className="w-100 h-auto" >
									<Image
										id="houseExperienceLeftImageTrans"
										src={trans_64x64}
										className="w-100 h-auto" />
									<Image
										id="houseExperienceLeftImage"
										src={bedroom}
										className="w-100 min-h-100 h-100 position-absolute cover" />
								</Group>
								<Text
									id="houseExperienceLeftDescription"
									className="w-100 h-auto mt-24px text-primary-text ff-textMedium" >
									{t('house.houseExperienceLeftDescription')}
								</Text>
							</Group>
							<Group
								id="houseExperienceCenter"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-8px" >
								<Text
									id="houseExperienceCenterTitle"
									tag="h3"
									className="w-100 h-auto text-primary-text ff-regular" >
									{t('house.houseExperienceCenterTitle')}
								</Text>
								<Group
									id="houseExperienceCenterImageGroup"
									direction="horizontal"
									className="w-100 h-auto" >
									<Image
										id="houseExperienceCenterImageTrans"
										src={trans_64x64}
										className="w-100 h-auto" />
									<Image
										id="houseExperienceCenterImage"
										src={conscious_entrepeneurs}
										className="w-100 min-h-100 h-100 position-absolute cover" />
								</Group>
								<Text
									id="houseExperienceCenterDescription"
									className="w-100 h-auto mt-24px text-primary-text ff-textMedium" >
									{t('house.houseExperienceCenterDescription')}
								</Text>
							</Group>
							<Group
								id="houseExperienceRight"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-8px" >
								<Text
									id="houseExperienceRightTitle"
									tag="h3"
									className="w-100 h-auto text-primary-text ff-regular" >
									{t('house.houseExperienceRightTitle')}
								</Text>
								<Group
									id="houseExperienceRightImageGroup"
									direction="horizontal"
									className="w-100 h-auto" >
									<Image
										id="houseExperienceRightImageTrans"
										src={trans_64x64}
										className="w-100 h-auto" />
									<Image
										id="houseExperienceRightImage"
										src={wellbeing_perks}
										className="w-100 h-fill position-absolute cover" />
								</Group>
								<Text
									id="houseExperienceRightDescription"
									className="w-100 h-auto mt-24px text-primary-text ff-textMedium" >
									{t('house.houseExperienceRightDescription')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="houseRedBarSeparator"
						direction="vertical"
						className="w-100 min-h-120px h-120px bg-primary mt-48px" >
					</Group>
					<Group
						id="houseRituals"
						direction="vertical"
						className="w-100 h-auto mt-48px" >
						<Text
							id="houseRitualsTitle"
							tag="h1"
							className="w-100 h-auto p-24px text-primary-text ff-regular text-center" >
							{t('house.houseRitualsTitle')}
						</Text>
						<Group
							id="houseRitualsGroup"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center" >
							<Group
								id="houseRitualsLeft"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-32px" >
								<Text
									id="houseRitualsLeftTitle"
									tag="h3"
									className="max-w-135px w-100 h-auto text-primary-text ff-regular" >
									{t('house.houseRitualsLeftTitle')}
								</Text>
								<Group
									id="houseRitualsCircleLeft"
									direction="vertical"
									className="w-120px h-auto border-top border-bottom border-start border-end border-radius-400px position-absolute" >
									<Image
										id="houseExperienceLeftImageTrans1651590136191"
										src={trans_64x64}
										className="w-100 h-auto" />
								</Group>
								<Text
									id="houseRitualsLeftDescription"
									className="w-100 h-auto mt-16px text-primary-text ff-textMedium" >
									{t('house.houseRitualsLeftDescription')}
								</Text>
							</Group>
							<Group
								id="houseRitualsCenter"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-32px mx-48px" >
								<Text
									id="houseRitualsCenterTitle"
									tag="h3"
									className="max-w-135px w-100 h-auto text-primary-text ff-regular" >
									{t('house.houseRitualsCenterTitle')}
								</Text>
								<Group
									id="houseRitualsCircleCenter"
									direction="vertical"
									className="w-120px h-auto border-top border-bottom border-start border-end border-radius-400px position-absolute" >
									<Image
										id="houseExperienceLeftImageTrans1651590630371"
										src={trans_64x64}
										className="w-100 h-auto" />
								</Group>
								<Text
									id="houseRitualsCenterDescription"
									className="w-100 h-auto mt-16px text-primary-text ff-textMedium" >
									{t('house.houseRitualsCenterDescription')}
								</Text>
							</Group>
							<Group
								id="houseRitualsRight"
								direction="vertical"
								className="min-w-300px w-fill h-auto p-32px" >
								<Text
									id="houseRitualsRightTitle"
									tag="h3"
									className="max-w-135px w-100 h-auto text-primary-text ff-regular" >
									{t('house.houseRitualsRightTitle')}
								</Text>
								<Group
									id="houseRitualsCircleRight"
									direction="vertical"
									className="w-120px h-auto border-top border-bottom border-start border-end border-radius-400px position-absolute" >
									<Image
										id="houseExperienceLeftImageTrans1651590634409"
										src={trans_64x64}
										className="w-100 h-auto" />
								</Group>
								<Text
									id="houseRitualsRightDescription"
									className="w-100 h-auto mt-16px text-primary-text ff-textMedium" >
									{t('house.houseRitualsRightDescription')}
								</Text>
							</Group>
						</Group>
						<Text
							id="houseRitualsApply"
							onClick={houseRitualsApplyOnClick}
							className="w-auto h-auto border-radius-50px align-center bg-primary px-16px py-8px mt-48px text-text-on-primary ff-regular" >
							{t('house.houseRitualsApply')}
						</Text>
					</Group>
				</Group>
				<Group
					id="houseFooter"
					direction="auto"
					className="w-100 h-auto bg-gray mt-64px" >
					<Group
						id="homeFooterLeft1651534256877"
						direction="vertical"
						className="min-w-320px w-fill h-auto px-16px py-48px" >
						<Image
							id="homeFooterLogo1651534256877"
							src={circles}
							className="w-160px h-auto valign-center" />
						<Text
							id="homeFooterCopyright1651534256877"
							className="w-100 h-auto mt-16px text-secondary-text ff-textMedium" >
							{t('house.homeFooterCopyright1651534256877')}
						</Text>
						<Group
							id="homeFooterSocialIconsGroup1651534256877"
							direction="horizontal"
							className="w-100 h-auto mt-72px" >
							<Image
								id="homeFooterLinkedinButton1651534256877"
								onClick={homeFooterLinkedinButton1651534256877OnClick}
								src={ic_linkedin}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterInstagramButton1651534256877"
								onClick={homeFooterInstagramButton1651534256877OnClick}
								src={ic_instagram}
								className="w-52px h-auto me-32px" />
							<Image
								id="homeFooterWhatsappButton1651534256877"
								onClick={homeFooterWhatsappButton1651534256877OnClick}
								src={ic_whatsapp}
								className="w-52px h-auto me-32px" />
						</Group>
					</Group>
					<Group
						id="homeFooterRight1651534256877"
						direction="vertical"
						className="max-w-400px min-w-300px w-fill h-auto px-16px py-48px" >
						<Text
							id="homeFooterCompany1651534256877"
							className="w-100 h-auto ff-textBold" >
							{t('house.homeFooterCompany1651534256877')}
						</Text>
						<Text
							id="homeFooterCookies1651534256877"
							onClick={homeFooterCookies1651534256877OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('house.homeFooterCookies1651534256877')}
						</Text>
						<Text
							id="homeFooterPrivacy1651534256877"
							onClick={homeFooterPrivacy1651534256877OnClick}
							className="w-100 h-auto mt-32px ff-textMedium" >
							{t('house.homeFooterPrivacy1651534256877')}
						</Text>
						<Group
							id="homeFooterAddressGroup1651534256877"
							onClick={homeFooterAddressGroup1651534256877OnClick}
							direction="vertical"
							className="w-100 h-auto mt-32px" >
							<Text
								id="homeFooterAddress1651534256877"
								className="w-100 h-auto ff-textMedium" >
								{t('house.homeFooterAddress1651534256877')}
							</Text>
							<Text
								id="homeFooterCity1651534256877"
								className="w-100 h-auto ff-textMedium" >
								{t('house.homeFooterCity1651534256877')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="houseFooterBottom"
					direction="vertical"
					className="w-100 min-h-8px h-8px bg-primary" >
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
