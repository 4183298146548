import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Web } from '../components';

export const Policy_dialogDialog = () => {
    const { run } = useFunctions();
  // Custom exports:

  // Custom functions:

  return (
    
    
			<Offcanvas id="policy_dialog" placement="end">
					<Offcanvas.Body>
						<Web
							id="policyWeb"
							src="@property.policyURL"
							className="w-fill h-fill" />
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Policy_dialogDialog;
