import React from 'react';

// Custom imports:
import Policy_dialogDialog from './Policy_dialogDialog';



export const Dialogs = () => {
  return (
    <>
      
			<Policy_dialogDialog />
      
    </>
  );
};

export default Dialogs;
